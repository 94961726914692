// import React,{useEffect} from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import Decrease from '../CountChange/Decrease';
import Increase from '../CountChange/Increase';
import Loader from '../Loader/Loader';
import { v4 as uuidv4 } from "uuid";
// import {FaWalking} from 'react-icons/fa'

export default function ListData({ loading,setChartData, filterbox, togglemode, setStateSelected, data, setStats, city, setCity, dateRange }: any) {
    const navigate = useNavigate();
    const params = useParams()
    const gotoState = (d: any) => {
        let path = params.statename === undefined ? "state" : null
        if (path === null) {
            setStateSelected(toCapitalize(d.region.name).split(' ').join(""))
            setCity(toCapitalize(d.region.name));
            // setStats(d);
            // setChartData(null)
        } else {
            navigate(`/${path}/${d}`)
        }
    }
    const toCapitalize = (string: String) => {
        let list = string.split(" ")
        for (let i in list) {
            list[i] = list[i].charAt(0) + list[i].slice(1).toLowerCase()
        }
        return list.join(" ");
    }
    // <p className='d-flex justify-content-center'>Loading...</p>
    return (
        <div className='total_list'>
            <div id="table-scroll" className="table-scroll" style={params.statename===undefined?{maxHeight:'52vh'}:{maxHeight:'62vh',minHeight:'62vh'}}>
                {loading ? <Loader />:
                    <table id="main-table" className="main-table">
                        <thead>
                            <tr>
                                <th scope="col">{data ? data.meta.region.name : null}</th>
                                {data ? Object.keys(data.meta).map((m: any) => {
                                    return (
                                        <>
                                        {/* Custom handling for totalGradable */}
                                        {m === "region" || m === "percentage" ? null :
                                            <th key={uuidv4()} scope="col">
                                            {m === "totalGradable" && dateRange === "november" 
                                                ? "Other Diseases" 
                                                : m === "totalGradable"
                                                ? null
                                                : data.meta[m]}
                                        </th>
                                        }
                                    </>
                                    )
                                }) : null}
                            </tr>
                        </thead>
                        
                        <tbody>
                        {data ? city === null || city === undefined ? data.data.map((st: any, ind: any) => {
                            return (
                                <tr key={uuidv4()} onClick={() => gotoState(params.statename === undefined ? toCapitalize(st.region.name) : st)}>
                                    <th>{toCapitalize(st.region.name)}</th>
                                    {Object.keys(data.meta).map((d: any) => {
                                        return (
                                            <>
                            {d !== "region" && d !== "percentage" ?
                                <td key={uuidv4()}>
                                    <div className="table_data">
    {/* Custom handling for totalGradable, shown only if dateRange is "november" */}
    {d === "totalGradable" ? (
        dateRange === "november" ? <p>{st[d]}</p> : null
    ) : (
        <>
            {st.percentage[d] < 0 ? (
                <Decrease data={st.percentage[d]} />
            ) : st.percentage[d] > 0 ? (
                <Increase data={st.percentage[d]} />
            ) : null}
            <p>{st[d]}</p>
        </>
    )}
</div>
                                </td>
                                : null
                            }
                        </>
                                        )
                                    })}
                                </tr>
                            );
                        }) :
                        data.data.map((ct: any, ind: any) => {
                            return (
                                <tr key={uuidv4()}>
                                    <th>{toCapitalize(ct.region.name)}</th>
                                    {Object.keys(data.meta).map((d: any) => {
                                        return (
                                            <>
                                {d !== "region" && d !== "percentage" ?
                                    <td key={uuidv4()}>
                                        <div className="table_data">
                                            {/* Custom handling for totalGradable */}
                                            {d === "totalGradable" ?
                                                <p>{ct[d]}</p> :
                                                <>
                                                    {ct.percentage[d] < 0 ? <Decrease data={ct.percentage[d]} /> :
                                                        ct.percentage[d] > 0 ? <Increase data={ct.percentage[d]} /> : null}
                                                    <p>{ct[d]}</p>
                                                </>
                                            }
                                        </div>
                                    </td>
                                    : null
                                }
                            </>
                                        )
                                    })}
                                </tr>
                            );
                        })
                        : null}
                        </tbody>
                    </table>
                }
            </div>
        </div>
    )
}

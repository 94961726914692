import React from "react";
import "./filterbox.css";
import { Tooltip } from "react-tooltip";

type FilterType = {
    filterbox: String | null;
    setFilter: any;
};
export default function FilterBox({ filterbox, setFilter }: FilterType) {
    return (
        <div className="filterbox mt-0">
            <div
                data-tooltip-content="All time data"
                className={
                    filterbox === "all_time" ? "all_time active" : "all_time"
                }
                onClick={setFilter}
            >
                <span>Total</span>
                <Tooltip anchorSelect=".all_time" />
            </div>
            <div
                data-tooltip-content="Last five years data"
                className={
                    filterbox === "five_years"
                        ? "five_years active"
                        : "five_years"
                }
                onClick={setFilter}
            >
                <span>5Y</span>
                {/* {fiveYearsTooltip===false?<span className='filter_tooltip'>Last five years data</span>:null} */}
                <Tooltip anchorSelect=".five_years" />
            </div>
            <div
                data-tooltip-content="Last one year's data"
                className={
                    filterbox === "one_year" ? "one_year active" : "one_year"
                }
                onClick={setFilter}
            >
                <span>1Y</span>
                <Tooltip anchorSelect=".one_year" />
                {/* {oneYearTooltip?<span className='filter_tooltip'>Last one year data</span>:null} */}
            </div>
            <div
                data-tooltip-content="Last six months data"
                className={
                    filterbox === "six_months"
                        ? "six_months active"
                        : "six_months"
                }
                onClick={setFilter}
            >
                <span>6M</span>
                <Tooltip anchorSelect=".six_months" />
                {/* {sixMonthsTooltip?<span className='filter_tooltip'>Last six months data</span>:null} */}
            </div>
            <div
                data-tooltip-content="Last three months data"
                className={
                    filterbox === "three_months"
                        ? "three_months active"
                        : "three_months"
                }
                onClick={setFilter}
            >
                <span>3M</span>
                <Tooltip anchorSelect=".three_months" />
                {/* {threeMonthsTooltip?<span className='filter_tooltip'>Last three months data</span>:null} */}
            </div>
            <div
                data-tooltip-content="Last one month's data"
                className={
                    filterbox === "one_month" ? "one_month active" : "one_month"
                }
                onClick={setFilter}
            >
                <span>1M</span>
                <Tooltip anchorSelect=".one_month" />
                {/* {oneMonthTooltip?<span className='filter_tooltip'>Last one month data</span>:null} */}
            </div>
            <div
                data-tooltip-content="Last one week's data"
                className={
                    filterbox === "one_week" ? "one_week active" : "one_week"
                }
                onClick={setFilter}
            >
                <span>7D</span>
                <Tooltip anchorSelect=".one_week" />
                {/* {oneWeekTooltip?<span className='tooltip'>Last one week data</span>:null} */}
            </div>
            <div
                data-tooltip-content="Last day's data"
                className={
                    filterbox === "one_day" ? "one_day active" : "one_day"
                }
                onClick={setFilter}
            >
                <span>2D</span>
                <Tooltip anchorSelect=".one_day" />
                {/* {oneWeekTooltip?<span className='tooltip'>Last one week data</span>:null} */}
            </div>
            <div
                data-tooltip-content="Today's data"
                className={filterbox === "today" ? "today active" : "today"}
                onClick={setFilter}
            >
                <span>1D</span>
                <Tooltip anchorSelect=".today" />
                {/* {oneWeekTooltip?<span className='tooltip'>Last one week data</span>:null} */}
            </div>
            <div
                data-tooltip-content="Diabetes month data"
                className={
                    filterbox === "november" ? "november active" : "november"
                }
                onClick={setFilter}
                style={{width:"100px"}}
            >
                <span>Diabetes Month</span>
                <Tooltip anchorSelect=".november" />
            </div>
        </div>
    );
}

//imoprting ReactRouterDom
import React, {useEffect, useRef, useState} from 'react'
//import d3 from d3
import {select, geoPath, geoIdentity} from 'd3';
// import {zoom} from 'd3-zoom';
// import d3 from 'd3';
import {useNavigate} from 'react-router-dom';
//import CountryVisualizer.css
import './CountryVisualizer.css'
//importing the IndiaData from india.jso.
import IndiaData from '../../projected_maps/india.json'

//importing the feature form topojsonclient
import {feature} from 'topojson-client';
import Loader from '../Loader/Loader';
import ChoroplethMap from '../ChoroplethMap/ChoroplethMap';
  
export default function CountryVisualizer({mapSelection,setMapSelection,dateRange,loading,data,setSelected,togglemode,stateselected,setStateSelected,stats,setStats,ismapname,setMapName}:any) {

  const navigate = useNavigate();
  //creating a ref to svg
  const svgRef:any = useRef();
  const [maptype,setMaptype] = useState("Total Screened")
  const [rgb,setRGB] = useState([40,167,69])
  const [tooltipcount,setTooltipcount] = useState(0);
  const [tooltip,setTooltip] = useState<any>(false);
  const [count,setCount] = useState<any>(0)
  const [tooltiptopic,setTooltipTopic] = useState("")
  const [name,setName] = useState(stats)
  const [noavailable,setNotAvailable] = useState<any>(null);
  const [fill,setFill] = useState<any>([])
  

  // Functions Start

  // Counter animation function
  const countCounter = (highest:number)=>{
    setCount(highest)

    // let i = 1
    // let timer = setInterval(() => {
    //   if(Math.floor(Math.log10(i) + 1)>=4){
    //     i+=1000;
    //   }else{
    //     i += 100;
    //   }
    //   setCount(i)
    //   if (i >= highest){
    //     clearInterval(timer)       
    //     setCount(highest)
    //   }
    // }, 40);
  }

   // onhover map stroke function

  const mapStroke = (elem:HTMLElement)=>{
    if(togglemode==="1"){
      if(ismapname==="camps"){
        elem.style.stroke = "rgb(138 218 255)"
      }
      if(ismapname==="scans"){
        elem.style.stroke = "rgb(159 255 181)"
      }
      if(ismapname==="pcases"){
        elem.style.stroke = "rgb(255 160 201)"
      }
      if(ismapname==="districts"){
        elem.style.stroke = "rgb(213 213 213)"
      }
    }else{
      if(ismapname==="camps"){
        elem.style.stroke = "rgb(0 33 49)"
      }
      if(ismapname==="scans"){
        elem.style.stroke = "rgb(0 59 13)"
      }
      if(ismapname==="pcases"){
        elem.style.stroke = "rgb(57 0 25)"
      }
      if(ismapname==="districts"){
        elem.style.stroke = "rgb(38 38 38)"
      }
    }
  }
  // onselect map fill function

  const mapFill = (elem:HTMLElement)=>{
    if(togglemode==="1"){
      if(ismapname==="camps"){
        elem.style.fill = "rgb(138 218 255)"
      }
      if(ismapname==="scans"){
        elem.style.fill = "rgb(159 255 181)"
      }
      if(ismapname==="pcases"){
        elem.style.fill = "rgb(255 160 201)"
      }
      if(ismapname==="districts"){
        elem.style.fill = "rgb(213 213 213)"
      }
    }else{
      if(ismapname==="camps"){
        elem.style.fill = "rgb(0 33 49)"
      }
      if(ismapname==="scans"){
        elem.style.fill = "rgb(0 59 13)"
      }
      if(ismapname==="pcases"){
        elem.style.fill = "rgb(57 0 25)"
      }
      if(ismapname==="districts"){
        elem.style.fill = "rgb(38 38 38)"
      }
    }
    elem.style.transition = "0.5s"
  }

  const filterState = (state_name:String)=>{
    // API call for State
    
    let cap_state_name = state_name.toUpperCase();
    let list_states = data?.data??[];
    let result_data = list_states.filter((st_nm:any)=>{
      return st_nm.region.name.toUpperCase()===cap_state_name;
    })
    // console.log(cap_state_name,list_states,result_data.length)
    if(result_data.length===0){
      return null;
    }else{
      return result_data[0]
    }
  }


  // Set Map name function
  const setMap = (e:any)=>{
    if(e.target.className === "camp-selector"){
      setMapName("camps")
    }
    if(e.target.className === "scan-selector"){
      setMapName("scans")
    }
    if(e.target.className === "pcase-selector"){
      setMapName("pcases")
    }
    if(e.target.className === "districts-selector"){
      setMapName("districts")
    }
  }

  const getMaxData = (data:any,type:String)=>{
    let max = -1;
    if(type==="pcases"){
      for(let i in data){
        if(data[i]['totalCases']>max){
          max = data[i]['totalCases']
        }
      }
    }
    if(type==="camps"){
      for(let i in data){
        if(data[i]['camps']>max){
          max = data[i]['camps']
        }
      }
    }
    if(type==="scans"){
      for(let i in data){
        if(data[i]['totalScans']>max){
          max = data[i]['totalScans']
        }
      }
    }
    if(type==="districts"){
      for(let i in data){
        if(data[i]['totalGradable']>max){
          max = data[i]['totalGradable']
        }
      }
    }
    return max;
  }

  const toCapitalize = (string:String)=>{
    let list = string.toString().split(" ")
    for(let i in list){
        list[i] = list[i].charAt(0) + list[i].slice(1).toLowerCase()
    }
    return list.join(" ");
  }

  const getFillData = (data:any,high_value:number,type:String)=>{
    let fill_data = []
    if(high_value!==0){

      if(type==="pcases"){
        for(let i in data){
          fill_data.push({
            "key":toCapitalize(data[i].region.name),
            "fill_perc":data[i]['totalCases']/high_value
          })
        }
      }
      if(type==="camps"){
        for(let i in data){
          fill_data.push({
            "key":toCapitalize(data[i].region.name),
            "fill_perc":data[i]['camps']/high_value
          })
        }
      }
      if(type==="scans"){
        for(let i in data){
          fill_data.push({
            "key":toCapitalize(data[i].region.name),
            "fill_perc":data[i]['totalScans']/high_value
          })
        }
      }
      if(type==="districts"){
        for(let i in data){
          fill_data.push({
            "key":toCapitalize(data[i].region.name),
            "fill_perc":data[i]['totalGradable']/high_value
          })
        }
      }
    }else{
      for(let i in data){
          fill_data.push({
          "key":toCapitalize(data[i].region.name),
          "fill_perc":0
          })
      }
    }
    return fill_data
  }

  // Map view change
  const mapChange = (e:any) => {
    setMapSelection(e.target.value)
  }
  // Functions end

  useEffect(()=>{
    if(data){
      if(ismapname==="pcases"){
        setMaptype("Total Positive Cases")
        setRGB([255,7,58])
        let maxCases = getMaxData(data.data,ismapname)
        let fillData = getFillData(data.data,maxCases,ismapname);
        setFill(fillData)
      }
      if(ismapname==="scans"){
        setRGB([40,167,69])
        setMaptype("Total Screened")
        let maxScans = getMaxData(data.data,ismapname)
        let fillData = getFillData(data.data,maxScans,ismapname);
        setFill(fillData)
      }
      if(ismapname==="camps"){
        setRGB([0,123,255])
        setMaptype("Total Camps")
        let maxCamps = getMaxData(data.data,ismapname)
        let fillData = getFillData(data.data,maxCamps,ismapname);
        setFill(fillData)
      }
      if(ismapname==="districts"){
        setRGB([108,117,125])
        setMaptype("Other Diseases")
        let maxGrad = getMaxData(data.data,ismapname)
        let fillData = getFillData(data.data,maxGrad,ismapname);
        setFill(fillData)
      }
      if(ismapname==="camps"){
        countCounter(stats["camps"])
      }
      if(ismapname==="scans"){
        countCounter(stats['totalScans'])
      }
      if(ismapname==="pcases"){
        countCounter(stats['totalCases'])
      }
      if(ismapname==="districts"){
        countCounter(stats['totalGradable'])
      }
      if(stats!==null && stats!=="India"){
        setName(toCapitalize(stats.region.name))
      }else{
        setStats("India")
      }
    }
  },[ismapname,stats,data])

  useEffect(()=>{
    // console.log(stateselected)
    if(mapSelection === "static_view"){
        const current:any = svgRef.current;
        const svg = select(current); 
        // passing the svgRef to the d3.select
        const x:any = IndiaData.objects.states;
        const y:any = IndiaData;
        const states:any = feature(y, x);
        //generating the state array
        //the pathGenerator function will be generating the d attributes for all the path elements
        const pathGenerator = geoPath(geoIdentity());
  
        
        const showMap = ()=>{
          svg.select('.state')
            .selectAll(".paths")
            .data(states.features)
            .enter()
            .append('path')
            .attr('class', (d:any)=>{
              let st = d.properties.st_nm.split(" ")
              st = st.join("")
              return `paths ${st}`
            })
            .attr('d', (state:any) => pathGenerator(state))
            .attr('stroke-width', 1)          
            .attr('stroke-opacity', 0.5)
            .attr('fill', (d:any,i:any)=>{
              let data = fill.filter((st:any)=>{
                return st.key === d.properties.st_nm;
              })
              if(data.length===1){
                return `rgba(${rgb[0]},${rgb[1]},${rgb[2]},${data[0].fill_perc})`
              }
              return `transparent`
            })
            .attr('stroke', `rgb(${rgb[0]},${rgb[1]},${rgb[2]})`)
            .style('cursor', 'pointer')
            .attr('pointer-events', 'all')
            .on('dblclick',(e,d:any)=>{
              let isPresent = data.data.filter((st:any)=>{
                return toCapitalize(st.region.name)===d.properties.st_nm;
              })
              if(isPresent.length){
                navigate(`/state/${d.properties.st_nm}`)
              }
            })
            .on('click',showStat)
            .on('mouseover',(e:any,d:any)=>{
              let elem = document.querySelector<HTMLElement>(`.${e.target.classList[1]}`)
              if(elem!=null){
                if(stateselected===null){
                  let getState = filterState(d.properties.st_nm)
                  if(getState===null){
                    setNotAvailable(`No data available for ${d.properties.st_nm}`)
                    setTimeout(() => {
                      setNotAvailable(null)
                    }, 3000);
                    setSelected(null)
                  }else{
                    setStats(getState)
                    setSelected(getState.region.name)
                  }
                  // debounceState(getState,1000)()
                  mapStroke(elem)
                  elem.style.strokeWidth = '1px';
                  elem.style.strokeOpacity= '1'
                }
              }
            })
            .on('mouseout',()=>{
              if(stateselected===null){
                svg.select('.state')
                .selectAll('.paths')
                .remove()
                showMap()
              }
            })
        }
        const showStat = (e:any,d:any)=>{
          let getState = filterState(d.properties.st_nm)
          if(getState){
            if(!tooltip && tooltipcount===0){
              let count = 1;
              setTooltipTopic("Double click to see details of this state")
              setTooltip(true);
              let timer = setInterval(()=>{
                setTooltipTopic("Click again on that state to deselect state")
                count += 1
                if(count>2){
                  setTooltip(false)
                  setTooltipTopic("")
                  count = 1;
                  clearInterval(timer)
                }
              },3000)
            }
            setTooltipcount(tooltipcount+1);
            if(tooltipcount>=4){
              setTooltip(false);
              setTooltipcount(0)
            }
            if(stateselected===null){
              setStats(getState)
              setSelected(getState.region.name)
              setStateSelected(e.target.classList[1])
            }else{
              if(e.target.classList[1]===stateselected){
                setStateSelected(null)
                setSelected(null)
              }
            }
          }else{
            setNotAvailable(`No data available for ${d.properties.st_nm}`)
            setTimeout(() => {
              setNotAvailable(null)
            }, 3000);
          }
        }
        svg.select('.state')
          .selectAll('.paths')
          .remove()
        showMap()
        if(stateselected!==null){
          svg.select('.state')
              .selectAll('.paths')
              .remove()
          showMap()
          let elem = document.querySelector<HTMLElement>(`.${stateselected}`)
          if(elem!=null){
            mapFill(elem)
            elem.style.strokeWidth = '1px';
            elem.style.strokeOpacity= '1'
            // elem.style.animation = '0.5s cubic-bezier(0.58, 0.54, 1, 1) 0s 1 normal none running fade'
          }
        }
        svg.attr("viewBox" , "-200 0 800 500" ).attr("preserveAspectRatio" , "xMidYMid meet").attr("pointer-events"  , 'auto');
    }
    // else{
    //   console.log("map view")
    // }
  },[svgRef,tooltip,ismapname,stateselected,togglemode,tooltipcount,fill,rgb,data,mapSelection])

  return (
    
    <div className='wrapper zoom'>
      {/* the actual svg which is manipulated by d3 */}
      <div className="short-data" style={{color:`rgb(${rgb[0]},${rgb[1]},${rgb[2]})`}}>
        <div className="india-data">
          <p>{stats==="India" || stats===null?"India":stats.region.name}</p>
          {loading?<Loader />:<h2>{count}</h2>}
          
        </div>
        <div className="states-data">
          <p>{maptype}</p>
          <div className="map-selector">
            <span>Type of map</span>
            <select name="maps" id="maps" onChange={mapChange}>
              <option value="static_view">Static Map View</option>
              <option value="map_view">Global Map View</option>
            </select>
            <div className="selectors">
              <div className={ismapname==="camps"?"camp-selector active":"camp-selector"} onClick={setMap}></div>
              <div className={ismapname==="scans"?"scan-selector active":"scan-selector"} onClick={setMap}></div>
              <div className={ismapname==="pcases"?"pcase-selector active":"pcase-selector"} onClick={setMap}></div>
              <div className={ismapname==="districts"?"districts-selector active":"districts-selector"} onClick={setMap}></div>
            </div>
          </div>
        </div>
      </div>
      {
        mapSelection === "static_view" ? 
        <svg ref={svgRef} className="mapSvg" >
          <g className="state" fill='red'></g>
        </svg> : <ChoroplethMap fill={fill} dateRange={dateRange} togglemode={togglemode} ismapname={ismapname} data={data} />
      }
      {/* {data ?<ChoroplethMap fill={fill} dateRange={dateRange} togglemode={togglemode} ismapname={ismapname} data={data} /> :null} */}
      
      <div className="map_tooltip">
        {tooltip?<p>{tooltiptopic}</p>:null}
        {noavailable!==null?<p>{noavailable}</p>:null}
      </div>
    </div>
    
  )
}

import axios from 'axios';
import React, { useState,useEffect } from 'react'
import { useParams } from 'react-router-dom';
import './statestates.css'
import Loader from '../Loader/Loader';
import ErrorPage from '../../pages/ErrorPage/ErrorPage';

export default function StatBox({mapSelection,setStats,loading,city,setLoading,selected,ismapname,setMapName,stats,dateRange}:any) {
  const [campdata,setCampData] = useState(0);
  const [scandata,setScanData] = useState(0);
  const [casedata,setCaseData] = useState(0);
  const [casedrdata,setDR3Data] = useState(0);
  const [totalCamps,setTotalCamps] = useState(0);
  const [totalScans,setTotalScans] = useState(0);
  const [totalDR,setTotalDR] = useState(0);
  const [totalDR3,setTotalDR3] = useState(0);
  const [error,setError] = useState<any>(null);

  const params = useParams();
  useEffect(()=>{
    const syear = dateRange[0].toLocaleString("default",{year:"numeric"});
    const smonth = dateRange[0].toLocaleString("default",{month:"2-digit"});
    const sday = dateRange[0].toLocaleString("default",{day:"2-digit"});
    const startDate = syear+"-"+smonth+"-"+sday

    const eyear = dateRange[1].toLocaleString("default",{year:"numeric"});
    const emonth = dateRange[1].toLocaleString("default",{month:"2-digit"});
    const eday = dateRange[1].toLocaleString("default",{day:"2-digit"});
    const endDate = eyear+"-"+emonth+"-"+eday
    let url = process.env.REACT_APP_API;
    if(params.statename && city){
      url = url + `/data/counts?country=India&state=${params.statename}&district=${city}&startDate=${startDate}&endDate=${endDate}`
    }else{
      if(params.statename && (city===null || city===undefined)){
        url = url + `/data/counts?country=India&state=${params.statename}&startDate=${startDate}&endDate=${endDate}`
      }else{
        url = url + `/data/counts?country=India&startDate=${startDate}&endDate=${endDate}`
      }
    }
    if(url!==undefined){
      setLoading(true)
      axios.get(url).then((res)=>{
        setCampData(res?.data?.result?.data[0]?.camps??0)
        setScanData(res?.data?.result?.data[0]?.counts[0]??0)
        setCaseData(res?.data?.result?.data[0]?.counts[1]??0)
        setDR3Data(res?.data?.result?.data[0]?.counts[2]??0)
        let defaultData = {
          totalScans: res?.data?.result?.data[0]?.counts[0]??0, 
          totalCases: res?.data?.result?.data[0]?.counts[1]??0, 
          totalGradable: res?.data?.result?.data[0]?.counts[2]??0, 
          region: {
            name:res?.data?.result?.data[0]?.region?.name??0,
            code:res?.data?.result?.data[0]?.region?.code??0
          }, 
          camps: res?.data?.result?.data[0]?.camps??0
        }
        setStats(defaultData)
        setLoading(false)
      }).catch((error)=>{
        setError(error)
      })
    }
    
  },[city,dateRange,mapSelection])
  useEffect(()=>{
    if(stats){
      setCampData(stats?.camps??0)
      setScanData(stats?.totalScans??0)
      setCaseData(stats?.totalCases??0)
      setDR3Data(stats?.totalGradable??0)
    }
  },[selected])
  useEffect(()=>{
    if(stats && !params.statename){
      setCampData(stats['camps'])
      setScanData(stats['totalScans'])
      setCaseData(stats['totalCases'])
      setDR3Data(stats['totalGradable'])
    }
  },[stats])

  const setMapColor = (e:any)=>{
    let tag = e.target.tagName
    let classname;
    if(tag==="H1" || tag==="P"){
      classname = e.target.parentElement.className
    }else{
      classname = e.target.className;
    }
    if(classname==="camps"){
      setMapName("camps")
    }
    if(classname==="scans"){
      setMapName("scans")
    }
    if(classname==="pcases"){
      setMapName("pcases")
    }
    if(classname==="districts"){
      setMapName("districts")
    }
  }

  if(error){
    return (
      <ErrorPage error={error?.message??"Error"} />
    )
  }
  return (
    <div className='statstates'>
      <div className="stats">
      <div className={ismapname==="scans"?"scans map-active":"scans"} onClick={setMapColor}>
          <p>Total Screened</p>
          {loading?<Loader />:<h1>{scandata}</h1>}
        </div>
        <div className={ismapname==="camps"?"camps map-active":"camps"} onClick={setMapColor}>
          <p >Total Camps</p>
          {loading?<Loader />:<h1 >{campdata}</h1>}
          
        </div>
        
        <div className={ismapname==="pcases"?"pcases map-active":"pcases"} onClick={setMapColor}>
          <p>Total DR Positive</p>
          {loading?<Loader />:<h1>{casedata}</h1>}
        </div>
        {(new Date(dateRange[0]).getTime() === new Date("2024-10-29T00:00:00.000Z").getTime() &&
  new Date(dateRange[1]).getTime() === new Date("2024-11-30T00:00:00.000Z").getTime()) && (
  <div className={ismapname === "districts" ? "districts map-active" : "districts"} onClick={setMapColor}>
    <p>Other Diseases</p>
    {loading ? <Loader /> : <h1>{casedrdata}</h1>}
  </div>
)}


      </div>
    </div>
  )
}

import React, { useEffect,useState } from 'react'
import ListsData from '../ListsData/ListsData';
import axios from 'axios';
import ErrorPage from '../../pages/ErrorPage/ErrorPage';
type metaObject = {
    TotalCamps:String,
    TotalScans:String,
    TotalCases:String,
    code:String,
    key:String,
    timeSeries:{
        Date:String,
        TotalCamps:String,
        TotalScans:String,
        TotalCases:String,
    }
}
type dataObject = {
    TotalCamps:number,
    TotalScans:number,
    TotalCases:number,
    code:number,
    key:String,
    timeSeries:Array<{
        Date:String,
        TotalCamps:number,
        TotalScans:number,
        TotalCases:number,
    }>
}
type resultData = {
    country:String | null,
    data:Array<dataObject>,
    state:String | null,
    district: String | null;
    type:String,
    meta:metaObject
}

export default function ShowData({togglemode,dataShowType,selectedDataType,setDataShowType,selectState,setSelectState,selectDistrict,setSelectDistrict,setSelectedDataType,selectCity,setSelectCity,dateRange}:any) {
    const [showData,setShowData] = useState<resultData | null>(null);
    const [loading,setLoading] = useState<Boolean>(false);
    const [error,setError] = useState<any>(null);
    useEffect(()=>{
        const syear = dateRange[0].toLocaleString("default",{year:"numeric"});
        const smonth = dateRange[0].toLocaleString("default",{month:"2-digit"});
        const sday = dateRange[0].toLocaleString("default",{day:"2-digit"});
        const startDate = syear+"-"+smonth+"-"+sday

        const eyear = dateRange[1].toLocaleString("default",{year:"numeric"});
        const emonth = dateRange[1].toLocaleString("default",{month:"2-digit"});
        const eday = dateRange[1].toLocaleString("default",{day:"2-digit"});
        const endDate = eyear+"-"+emonth+"-"+eday
        dataListCall(startDate,endDate,selectedDataType,selectState,selectDistrict,selectCity)
    },[dataShowType,dateRange,selectedDataType,selectState,selectDistrict,selectCity])


    const dataListCall= (startDate:String,endDate:String,selectedDataType:any,selectState:any,selectDistrict:any,selectCity:any)=>{
        let url = process.env.REACT_APP_API;
        if(selectedDataType!==null && selectState===null && selectDistrict===null && selectCity===null){
            url = url + `/data/list?country=India&startDate=${startDate}&endDate=${endDate}&level=State`
        }
        else if(selectedDataType!==null && selectState!==null && selectDistrict ===null && selectCity===null){
            url = url +`/data/list?country=India&state=${selectState}&startDate=${startDate}&endDate=${endDate}&level=District`
        }
        else if(selectedDataType!==null && selectState!==null && selectDistrict !==null && selectCity===null){
            url = url +`/data/list?country=India&state=${selectState}&district=${selectDistrict}&startDate=${startDate}&endDate=${endDate}&level=City`
        }else{
            url = url +`/data/list?country=India&state=${selectState}&district=${selectDistrict}&city=${selectCity}&startDate=${startDate}&endDate=${endDate}`
        }
        setLoading(true)
        
        axios.get(url).then((res)=>{
            setShowData(res.data.result)
            setLoading(false)
        }).catch((err)=>{
            setError(err)
        })
    }
    if(error){
        return (
            <ErrorPage error={error?.message??"Error"} />
        )
    }
    return (
        <div className='total_list'>
            <ListsData loading={loading} togglemode={togglemode} showData={showData} dataShowType={dataShowType} setDataShowType={setDataShowType} setSelectState={setSelectState} setSelectDistrict={setSelectDistrict} setSelectedDataType={setSelectedDataType} setSelectCity={setSelectCity} dateRange={dateRange} />
        </div>
    )
}
